import { useEffect } from 'react'

import { useRouter } from 'next/router'
import type { Environment } from 'relay-runtime'

import { routeMap } from '../shared/routes'

import { getMessageFromUnknownError } from './getMessageFromUnknownError'
import { useCtaSource } from './routing/useCtaSource'
import useSessionId from './useSession'

import { useFBTrackingParams } from 'lib/analytics/useTrackingParams'
import {
    trackLoginCompleted,
    trackLoginFailed,
    trackSignUpCompleted,
    trackSignUpFailed,
} from 'lib/analyticsApi'
import { setAuthToken } from 'lib/cookieAuth'
import type { AppsFlyerPBATrackingFn } from 'lib/tracking/appsFlyerPBAClient'
import { AF_COMPLETE_REGISTRATION, AF_LOGIN } from 'lib/tracking/appsFlyerPBAClient'
import { FINANCEADS_SIGNUP } from 'lib/tracking/financeAds/consts'
import type { FinanceadsTrackingFn } from 'lib/tracking/financeAds/types'
import { SIGNUP } from 'lib/tracking/impact/consts'
import type { ImpactTrackingFn } from 'lib/tracking/impact/types'
import { redirectIfLoginParam } from 'modules/SignUpOrLogin/Hooks/useLoginRedirect'
import type { SignInWithAppleMutationResponse } from 'relay/mutations/signInWithApple'
import signInWithApple from 'relay/mutations/signInWithApple'
import type { SignUpWithAppleMutationResponse } from 'relay/mutations/signUpWithApple'
import signUpWithApple from 'relay/mutations/signUpWithApple'
import { captureException } from 'shared/sentry'

const STANDARD_ERROR_MESSAGE = 'Something went wrong. Please try again later'
const POP_UP_CLOSED_ERROR = 'popup_closed_by_user'
const NEW_SIGNIN_FLOW_ERROR = 'user_trigger_new_signin_flow'
const ERRORS_TO_IGNORE = [POP_UP_CLOSED_ERROR, NEW_SIGNIN_FLOW_ERROR]

/*
    These types are actually quite a bit bigger, but
    couldn't find an official type so just copied the relevant bits
*/
interface SignUpSuccessData extends CustomEvent {
    detail: {
        authorization: { id_token: string }
        user: {
            email: string
            name: {
                firstName?: string
                lastName?: string
            }
        }
    }
    environment: Environment
}

interface LoginSuccessData extends CustomEvent {
    detail: {
        authorization: { id_token: string }
    }
}

type SuccessData = LoginSuccessData & SignUpSuccessData

const LOGIN_TYPE = 'apple'

// typeguard https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
const responseTypeIsLogin = (
    _mutationResponse: SignInWithAppleMutationResponse | SignUpWithAppleMutationResponse,
    isLogin: boolean,
): _mutationResponse is SignInWithAppleMutationResponse => {
    return isLogin
}

const useAppleAuth = (
    relay: { environment: Environment },
    setStatus: (status?: any) => void,
    slug: string,
    signUp = false,
    impactTrackingFn: ImpactTrackingFn,
    financeadsTrackingFn: FinanceadsTrackingFn,
    appsFlyerPBATrackingFn: AppsFlyerPBATrackingFn,
    source?: string,
) => {
    const router = useRouter()
    const urlCtaSource = useCtaSource()
    const sessionId = useSessionId(false) || undefined
    const { fbp, fbclid } = useFBTrackingParams()

    useEffect(() => {
        const isLogin = window.location.pathname.includes(routeMap.login)
        if (signUp && isLogin) {
            // Fix a bug where this is called on the wrong auth page,
            // due to the listeners type being the same
            return
        }

        // @ts-ignore
        const appleJs = document.createElement('script')
        appleJs.src =
            'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
        appleJs.async = true
        document.body && document.body.appendChild(appleJs)

        // Internal tracking helpers
        const trackAuthFailed = ({ message }: { message: string }) => {
            if (isLogin) {
                trackSignUpFailed(LOGIN_TYPE, message)
            } else {
                trackLoginFailed(LOGIN_TYPE, message)
            }
        }

        const onAuthSuccess = (data: SuccessData) => {
            const { detail, environment } = data
            // user is only passed on the very first sign-in
            const { authorization, user } = detail

            const { id_token } = authorization
            if (user) {
                const { email, name } = user
                const { firstName, lastName } = name

                // auth with email + name + token_id
                authCallback(
                    id_token,
                    environment,
                    impactTrackingFn,
                    financeadsTrackingFn,
                    appsFlyerPBATrackingFn,
                    email,
                    firstName,
                    lastName,
                    source,
                )
            } else {
                // auth with token_id
                // TODO: Potentially move decoding on the client side,
                // but that requires tweaking the whole flow
                authCallback(
                    id_token,
                    environment,
                    impactTrackingFn,
                    financeadsTrackingFn,
                    appsFlyerPBATrackingFn,
                    source,
                )
            }
        }

        // ideally would change this prop typing to more accurately
        // reflect the two options above
        const authCallback = async (
            appleIdToken: string,
            environment: Environment,
            impactTrackingFn: ImpactTrackingFn,
            financeadsTrackingFn: FinanceadsTrackingFn,
            appsFlyerPBATrackingFn: AppsFlyerPBATrackingFn,
            source: string | undefined,
            email?: string,
            firstName?: string,
            lastName?: string,
        ) => {
            let tokenResponse

            try {
                tokenResponse = isLogin
                    ? await signInWithApple(relay.environment)({
                          appleIdToken,
                          email,
                          firstName,
                          lastName,
                          sessionId,
                      })
                    : await signUpWithApple(relay.environment)({
                          appleIdToken,
                          email,
                          firstName,
                          lastName,
                          sessionId,
                          source,
                      })
            } catch (error) {
                setStatus({ formError: getMessageFromUnknownError(error) })

                return
            }

            let accessToken
            if (responseTypeIsLogin(tokenResponse, isLogin)) {
                accessToken = tokenResponse.signInWithApple?.accessToken

                if (!tokenResponse.signInWithApple?.viewer.me) {
                    captureException(new Error('signInWithApple did not return a user'))
                    throw new Error(STANDARD_ERROR_MESSAGE)
                }
                // For sign in, we get the email from the mutation response since
                // you only receive the email from apple on the initial sign up
                const emailFromMutation = tokenResponse.signInWithApple?.viewer.me.userEmail
                const userId = tokenResponse.signInWithApple?.viewer.me?.id
                trackLoginCompleted(LOGIN_TYPE, emailFromMutation)
                appsFlyerPBATrackingFn(environment, AF_LOGIN, { customerUserId: userId! })
            } else {
                accessToken = tokenResponse.signUpWithApple?.accessToken
                const userId = tokenResponse.signUpWithApple?.viewer.me?.id
                // awful as string hack, but down this path email and id should be passed in.
                // This logic could do with refactoring to strongly type between login/signup, rather than defining optional strings
                const ctaSource = source ?? urlCtaSource
                trackSignUpCompleted(
                    LOGIN_TYPE,
                    slug,
                    email as string,
                    ctaSource,
                    environment,
                    fbclid || '',
                    fbp || '',
                )
                impactTrackingFn(environment, SIGNUP, { userId })
                financeadsTrackingFn(environment, FINANCEADS_SIGNUP, null)
                appsFlyerPBATrackingFn(environment, AF_COMPLETE_REGISTRATION, {
                    customerUserId: userId!,
                })
            }

            if (accessToken) {
                setAuthToken(accessToken)

                // check if any stored redirect cookies
                const redirectUri = redirectIfLoginParam()

                if (redirectUri) {
                    router.push(redirectUri)
                } else {
                    // take user to the home screen by default, checkout if there's a slug
                    if (slug) {
                        router.push({ pathname: routeMap.checkout(slug) })
                    } else {
                        router.push(routeMap.home)
                    }
                }
            } else {
                throw new Error(STANDARD_ERROR_MESSAGE)
            }
        }

        const onFailure = (event: Event) => {
            // @ts-ignore
            const message = event && event.detail && event.detail.error
            if (ERRORS_TO_IGNORE.includes(message)) return
            setStatus({ formError: STANDARD_ERROR_MESSAGE })
            trackAuthFailed({ message })
        }

        // have to use as here because of a longstanding type issue https://github.com/microsoft/TypeScript/issues/28357
        document.addEventListener('AppleIDSignInOnSuccess', onAuthSuccess as EventListener)

        // Listen for authorization failures
        document.addEventListener('AppleIDSignInOnFailure', onFailure)

        return () => {
            document.removeEventListener('AppleIDSignInOnSuccess', onAuthSuccess as EventListener)
            document.removeEventListener('AppleIDSignInOnFailure', onFailure)
        }
    }, [])
}

export default useAppleAuth
