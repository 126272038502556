import type { ReactNode } from 'react';
import React from 'react'

import Container from 'components/Container'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import Separator from 'components/Separator'
import { BareLayout } from 'layouts'
import type { LayoutProps } from 'layouts/BareLayout'

const OrSeparator = () => (
    <Container flexDirection="row" display="flex" alignItems="center">
        <Separator flex={1} />
        <Paragraph fontWeight="bold" flex={0} marginLeft={1} marginRight={1} marginTop={1}>
            OR
        </Paragraph>
        <Separator flex={1} />
    </Container>
)

type Props = {
    children: ReactNode
    seoProps: LayoutProps['seoProps']
}

const Layout: React.FC<Props> = ({ children, seoProps }) => (
    <BareLayout seoProps={seoProps}>
        <Container pb={4} pt={2}>
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                width="100%"
                maxWidth="378px"
                margin="auto"
            >
                {children}
            </Box>
        </Container>
    </BareLayout>
)

export { Layout, OrSeparator }
