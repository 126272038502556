import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type {
    SignInWithAppleInput,
    signInWithAppleMutationResponse,
} from '__generated__/signInWithAppleMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

export type SignInWithAppleMutationResponse = signInWithAppleMutationResponse

const signInWithAppleMutation =
    (relayEnv: Environment) =>
    (input: SignInWithAppleInput): Promise<signInWithAppleMutationResponse> => {
        return createMutationPromise<signInWithAppleMutationResponse>(relayEnv)({
            mutation: graphql`
                mutation signInWithAppleMutation($input: SignInWithAppleInput!) {
                    signInWithApple(input: $input) {
                        accessToken
                        viewer {
                            me {
                                id
                                userEmail
                            }
                        }
                    }
                }
            `,
            variables: { input },
        })
    }

export default signInWithAppleMutation
