import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type {
    SignInWithGoogleInput,
    signInWithGoogleMutationResponse,
} from '__generated__/signInWithGoogleMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

/** Send the `id_token` from Google Signin/SSO to our server, where it is validated
 * and exchanged for a (coincidentally JWT) `accessToken`, which one then must pass
 * as a cookie for all reqs (which last part is how our authn works in general). */
const signInWithGoogle =
    (relayEnv: Environment) =>
    ({
        token,
        hasOptedInToMarketing,
        source = 'web-default',
        sessionId,
    }: SignInWithGoogleInput): Promise<signInWithGoogleMutationResponse> => {
        return createMutationPromise<signInWithGoogleMutationResponse>(relayEnv)({
            mutation: graphql`
                mutation signInWithGoogleMutation($input: SignInWithGoogleInput!) {
                    signInWithGoogle(input: $input) {
                        accessToken
                        isFirstAppLogin
                        viewer {
                            features
                            toggles {
                                packs
                                packsOnboarding
                            }
                            me {
                                id
                                userEmail
                            }
                        }
                    }
                }
            `,
            variables: {
                input: {
                    token,
                    hasOptedInToMarketing,
                    source,
                    sessionId,
                },
            },
        })
    }

export default signInWithGoogle
