/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SignInWithAppleInput = {
    appleIdToken: string;
    email?: string | null | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    sessionId?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type signInWithAppleMutationVariables = {
    input: SignInWithAppleInput;
};
export type signInWithAppleMutationResponse = {
    readonly signInWithApple: {
        readonly accessToken: string;
        readonly viewer: {
            readonly me: {
                readonly id: string;
                readonly userEmail: string;
            } | null;
        };
    } | null;
};
export type signInWithAppleMutation = {
    readonly response: signInWithAppleMutationResponse;
    readonly variables: signInWithAppleMutationVariables;
};



/*
mutation signInWithAppleMutation(
  $input: SignInWithAppleInput!
) {
  signInWithApple(input: $input) {
    accessToken
    viewer {
      me {
        __typename
        id
        userEmail
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userEmail",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signInWithAppleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignInWithApplePayload",
        "kind": "LinkedField",
        "name": "signInWithApple",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signInWithAppleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignInWithApplePayload",
        "kind": "LinkedField",
        "name": "signInWithApple",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d13cf0e46a07a9495535c44668f8a697",
    "id": null,
    "metadata": {},
    "name": "signInWithAppleMutation",
    "operationKind": "mutation",
    "text": "mutation signInWithAppleMutation(\n  $input: SignInWithAppleInput!\n) {\n  signInWithApple(input: $input) {\n    accessToken\n    viewer {\n      me {\n        __typename\n        id\n        userEmail\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b6d50eb074858d5123852eeae2183322';
export default node;
