import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type {
    SignUpWithAppleInput,
    signUpWithAppleMutationResponse,
} from '__generated__/signUpWithAppleMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

export type SignUpWithAppleMutationResponse = signUpWithAppleMutationResponse

const signUpWithAppleMutation =
    (relayEnv: Environment) =>
    ({
        appleIdToken,
        email,
        firstName,
        lastName,
        hasOptedInToMarketing = null,
        source = 'web-default',
        sessionId,
    }: SignUpWithAppleInput): Promise<signUpWithAppleMutationResponse> => {
        const input = {
            appleIdToken,
            email,
            firstName,
            lastName,
            hasOptedInToMarketing,
            source,
            sessionId,
        }
        return createMutationPromise<signUpWithAppleMutationResponse>(relayEnv)({
            mutation: graphql`
                mutation signUpWithAppleMutation($input: SignUpWithAppleInput!) {
                    signUpWithApple(input: $input) {
                        accessToken
                        viewer {
                            me {
                                id
                            }
                        }
                    }
                }
            `,
            variables: { input },
        })
    }

export default signUpWithAppleMutation
