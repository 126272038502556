/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SignInWithGoogleInput = {
    token: string;
    hasOptedInToMarketing?: boolean | null | undefined;
    source?: string | null | undefined;
    sessionId?: string | null | undefined;
    clickedAdContentPieceId?: number | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type signInWithGoogleMutationVariables = {
    input: SignInWithGoogleInput;
};
export type signInWithGoogleMutationResponse = {
    readonly signInWithGoogle: {
        readonly accessToken: string;
        readonly isFirstAppLogin: boolean;
        readonly viewer: {
            readonly features: ReadonlyArray<string>;
            readonly toggles: {
                readonly packs: boolean;
                readonly packsOnboarding: boolean;
            };
            readonly me: {
                readonly id: string;
                readonly userEmail: string;
            } | null;
        };
    } | null;
};
export type signInWithGoogleMutation = {
    readonly response: signInWithGoogleMutationResponse;
    readonly variables: signInWithGoogleMutationVariables;
};



/*
mutation signInWithGoogleMutation(
  $input: SignInWithGoogleInput!
) {
  signInWithGoogle(input: $input) {
    accessToken
    isFirstAppLogin
    viewer {
      features
      toggles {
        packs
        packsOnboarding
        id
      }
      me {
        __typename
        id
        userEmail
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFirstAppLogin",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "packs",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "packsOnboarding",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userEmail",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signInWithGoogleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignInWithGooglePayload",
        "kind": "LinkedField",
        "name": "signInWithGoogle",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Toggles",
                "kind": "LinkedField",
                "name": "toggles",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signInWithGoogleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignInWithGooglePayload",
        "kind": "LinkedField",
        "name": "signInWithGoogle",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Toggles",
                "kind": "LinkedField",
                "name": "toggles",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5968ca8c044ebaf2a385735bd2ac6935",
    "id": null,
    "metadata": {},
    "name": "signInWithGoogleMutation",
    "operationKind": "mutation",
    "text": "mutation signInWithGoogleMutation(\n  $input: SignInWithGoogleInput!\n) {\n  signInWithGoogle(input: $input) {\n    accessToken\n    isFirstAppLogin\n    viewer {\n      features\n      toggles {\n        packs\n        packsOnboarding\n        id\n      }\n      me {\n        __typename\n        id\n        userEmail\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9d7555a852b1b33445a836b9aee220f8';
export default node;
