import React from 'react'

import type { TokenResponse } from '@react-oauth/google'
import { useGoogleLogin } from '@react-oauth/google'
import type { Environment } from 'relay-runtime'

import { useCtaSource } from '../../../lib/routing/useCtaSource'

import { LegacyButton as Button } from 'components/Button'
import Icon from 'components/Icon'
import { useFBTrackingParams } from 'lib/analytics/useTrackingParams'
import {
    trackLoginCompleted,
    trackLoginFailed,
    trackLoginPressed,
    trackSignUpCompleted,
    trackSignUpFailed,
    trackSignUpPressed,
} from 'lib/analyticsApi'
import { setAuthToken } from 'lib/cookieAuth'
import type { AppsFlyerPBATrackingFn } from 'lib/tracking/appsFlyerPBAClient'
import { AF_COMPLETE_REGISTRATION, AF_LOGIN } from 'lib/tracking/appsFlyerPBAClient'
import { FINANCEADS_SIGNUP } from 'lib/tracking/financeAds/consts'
import type { FinanceadsTrackingFn } from 'lib/tracking/financeAds/types'
import { SIGNUP as IMPACT_SIGNUP } from 'lib/tracking/impact/consts'
import type { ImpactTrackingFn } from 'lib/tracking/impact/types'
import useSessionId from 'lib/useSession'
import signInWithGoogle from 'relay/mutations/signInWithGoogle'
import { captureException } from 'shared/sentry'

export const SIGNIN = 'signin'
export const SIGNUP = 'signup'

export const AUTH_METHOD_GOOGLE = 'google'

export const SSO_USER_EXISTS_CODE = 'USER_EXISTS'
export const SSO_GOOGLE_EMAIL_UNVERIFIED_SIGN_UP_CODE = 'GOOGLE_EMAIL_UNVERIFIED_SIGN_UP'

export const SSO_USER_EXISTS_MESSAGE =
    '🙋 Trying to access your account? Sign in with your password.'
export const SSO_GOOGLE_EMAIL_UNVERIFIED_SIGN_UP_MESSAGE =
    'Please verify your Google email address.'
export const SSO_UNIDENTIFIED_ERROR_MESSAGE = 'An unidentified error occurred. Please try again.'

export type GoogleSSOButtonProps = {
    afterAuth: () => void
    page: typeof SIGNIN | typeof SIGNUP
    relay: { environment: Environment }
    impactTrackingFn: ImpactTrackingFn
    financeadsTrackingFn: FinanceadsTrackingFn
    appsFlyerPBATrackingFn: AppsFlyerPBATrackingFn
    source?: string
}

export const GoogleSSOButton = ({
    afterAuth,
    page,
    relay,
    impactTrackingFn,
    financeadsTrackingFn,
    appsFlyerPBATrackingFn,
    source,
}: GoogleSSOButtonProps) => {
    const [error, setError] = React.useState(undefined as string | undefined)

    const urlCtaSource = useCtaSource()
    const sessionId = useSessionId(false) || undefined
    const { fbp, fbclid } = useFBTrackingParams()

    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            onSign(
                afterAuth,
                relay,
                tokenResponse,
                page,
                setError,
                impactTrackingFn,
                financeadsTrackingFn,
                appsFlyerPBATrackingFn,
                source,
                urlCtaSource,
                sessionId,
                fbp,
                fbclid,
            )
        },
        onError: errorResponse => {
            trackPressed(page)
            handleError(errorResponse, page, setError)
        },
    })

    return (
        <div
            className="google-sso-button"
            id="google-sso-button"
            style={{ color: 'white', width: '100%' }}
        >
            <Button
                type="button"
                color="black"
                isBlock
                style={{ color: 'white', border: '1px solid grey', width: '100%' }}
                onClick={() => googleLogin()}
            >
                <Icon icon="RiGoogleFill" title="Google" color="white" mr={1} />
                Continue with Google
            </Button>
            {error ? (
                <div className="google-sso-button--error" style={{ color: 'red' }}>
                    {error}
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export const onSign = (
    afterAuth: () => void,
    relay: { environment: Environment },
    tokenResponse: TokenResponse,
    page: string,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>,
    impactTrackingFn: ImpactTrackingFn,
    financeadsTrackingFn: FinanceadsTrackingFn,
    appsFlyerPBATrackingFn: AppsFlyerPBATrackingFn,
    source: string | undefined,
    urlCtaSource: string | undefined,
    sessionId: string | undefined,
    fbp?: string,
    fbclid?: string,
) => {
    try {
        trackPressed(page)
        signInWithGoogle(relay.environment)({
            token: tokenResponse.access_token,
            hasOptedInToMarketing: null,
            source: source,
            sessionId,
        })
            .then(r => {
                const { userEmail, id } = r.signInWithGoogle!.viewer!.me!
                // whether a user has been created for the first time
                const isFirstLogin = r.signInWithGoogle!.isFirstAppLogin
                setAuthToken(r.signInWithGoogle!.accessToken)
                trackCompleted(
                    page,
                    userEmail,
                    id,
                    relay.environment,
                    isFirstLogin,
                    impactTrackingFn,
                    financeadsTrackingFn,
                    appsFlyerPBATrackingFn,
                    source,
                    urlCtaSource,
                    fbp,
                    fbclid,
                )
                afterAuth()
            })
            .catch(e => {
                handleError(e, page, setError)
            })
    } catch (e) {
        handleError(e, page, setError)
    }
}

export const handleError = (
    error: any,
    page: string,
    setError: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
    switch (error.code ?? error.extensions?.code) {
        case SSO_GOOGLE_EMAIL_UNVERIFIED_SIGN_UP_CODE:
            captureException(error)
            trackError(page, SSO_GOOGLE_EMAIL_UNVERIFIED_SIGN_UP_MESSAGE)
            return setError(SSO_GOOGLE_EMAIL_UNVERIFIED_SIGN_UP_MESSAGE)
        case SSO_USER_EXISTS_CODE:
            // @note: Do not `captureException` - this is an expected user error
            trackError(page, SSO_USER_EXISTS_MESSAGE)
            return setError(SSO_USER_EXISTS_MESSAGE)
        default:
            captureException(error)
            trackError(page, error)
            return setError(
                Object.keys(error).includes('message')
                    ? // For server errors
                      error.message
                    : Object.keys(error).includes('error_description')
                    ? // For Google errors
                      error.error_description
                    : SSO_UNIDENTIFIED_ERROR_MESSAGE,
            )
    }
}

export const trackCompleted = (
    page: string,
    email: string,
    userId: string,
    environment: Environment,
    isFirstLogin: boolean,
    impactTrackingFn: ImpactTrackingFn,
    financeadsTrackingFn: FinanceadsTrackingFn,
    appsFlyerPBATrackingFn: AppsFlyerPBATrackingFn,
    source: string | undefined,
    urlCtaSource: string | undefined,
    fbp?: string,
    fbclid?: string,
) => {
    if (isFirstLogin) {
        const ctaSource = source ?? urlCtaSource
        trackSignUpCompleted(
            AUTH_METHOD_GOOGLE,
            page,
            email,
            ctaSource,
            environment,
            fbclid || '',
            fbp || '',
        )
        impactTrackingFn(environment, IMPACT_SIGNUP, { userId })
        financeadsTrackingFn(environment, FINANCEADS_SIGNUP, null)
        appsFlyerPBATrackingFn(environment, AF_COMPLETE_REGISTRATION, { customerUserId: userId })
    } else {
        trackLoginCompleted(AUTH_METHOD_GOOGLE, email)
        appsFlyerPBATrackingFn(environment, AF_LOGIN, { customerUserId: userId })
    }
}

export const trackError = (page: string, message: string) =>
    (page === SIGNIN ? trackLoginFailed : trackSignUpFailed)(
        AUTH_METHOD_GOOGLE,
        typeof message === 'string' ? message : JSON.stringify(message),
    )

export const trackPressed = (page: string) =>
    (page === SIGNIN ? trackLoginPressed : trackSignUpPressed)(AUTH_METHOD_GOOGLE, '')
