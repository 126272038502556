/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SignUpWithAppleInput = {
    appleIdToken: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    skillLevel?: string | null | undefined;
    appUseCase?: string | null | undefined;
    email?: string | null | undefined;
    hasOptedInToMarketing?: boolean | null | undefined;
    source?: string | null | undefined;
    sessionId?: string | null | undefined;
    clickedAdContentPieceId?: number | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type signUpWithAppleMutationVariables = {
    input: SignUpWithAppleInput;
};
export type signUpWithAppleMutationResponse = {
    readonly signUpWithApple: {
        readonly accessToken: string;
        readonly viewer: {
            readonly me: {
                readonly id: string;
            } | null;
        };
    } | null;
};
export type signUpWithAppleMutation = {
    readonly response: signUpWithAppleMutationResponse;
    readonly variables: signUpWithAppleMutationVariables;
};



/*
mutation signUpWithAppleMutation(
  $input: SignUpWithAppleInput!
) {
  signUpWithApple(input: $input) {
    accessToken
    viewer {
      me {
        __typename
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signUpWithAppleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignUpWithApplePayload",
        "kind": "LinkedField",
        "name": "signUpWithApple",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signUpWithAppleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignUpWithApplePayload",
        "kind": "LinkedField",
        "name": "signUpWithApple",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "me",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "182115ac4ec0b958b576d34ebd0f304d",
    "id": null,
    "metadata": {},
    "name": "signUpWithAppleMutation",
    "operationKind": "mutation",
    "text": "mutation signUpWithAppleMutation(\n  $input: SignUpWithAppleInput!\n) {\n  signUpWithApple(input: $input) {\n    accessToken\n    viewer {\n      me {\n        __typename\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '69571a0c39cd57feea931ded6b4179f2';
export default node;
